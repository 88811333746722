import React, { useEffect, useState } from 'react';
import { fetchCars, deleteCar } from '../api';
import './Dashboard.css'; // Import CSS for table styling

function Dashboard() {
  const [cars, setCars] = useState([]);
  const [filteredCars, setFilteredCars] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });
  const [filters, setFilters] = useState({
    make: '',
    model: '',
    year: '',
    color: '',
  });
  const authToken = localStorage.getItem('jwtToken');

  useEffect(() => {
    if (!authToken) {
      console.error('No auth token found');
      return;
    }

    const loadCars = async () => {
      try {
        const carsData = await fetchCars(authToken);
        // Ensure carsData is an array before setting it in state         
        setCars(Array.isArray(carsData) ? carsData : []);
        setFilteredCars(Array.isArray(carsData) ? carsData : []);
      } catch (error) {
        console.error('Failed to fetch cars', error);
        setCars([]); // Fallback to an empty array if fetching fails    
      }
    };

    loadCars();
  }, [authToken]);

  useEffect(() => {
    applyFilters();
  }, [filters, cars]);

  const handleDeleteCar = async (carId) => {
    if (!authToken) {
      console.error('No auth token found');
      return;
    }

    try {
      await deleteCar(carId, authToken);
      setCars(cars.filter(car => car.id !== carId));
    } catch (error) {
      console.error('Failed to delete car', error);
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedCars = [...filteredCars].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

    setFilteredCars(sortedCars);
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? ' ▲' : ' ▼';
    }
    return '';
  };

  const calculateDaysAgo = (dateString) => {
    const createdDate = new Date(dateString);
    const currentDate = new Date();
    const timeDiff = currentDate - createdDate;
    const daysAgo = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return daysAgo === 0 ? "Today" : `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
  };

  const handleFilterChange = (event, key) => {
    setFilters({ ...filters, [key]: event.target.value });
  };

  const applyFilters = () => {
    const filtered = cars.filter(car =>
      (filters.make ? car.make === filters.make : true) &&
      (filters.model ? car.model === filters.model : true) &&
      (filters.year ? car.year === filters.year : true) &&
      (filters.color ? car.color === filters.color : true)
    );
    setFilteredCars(filtered);
  };

  const getUniqueValues = (key) => {
    return [...new Set(cars.map(car => car[key]))];
  };

  return (
    <div className="dashboard-container">
      <h2>Your Saved Cars</h2>

      {/* Filter dropdowns */}
      <div className="filters">
        <label>
          Make:
          <select value={filters.make} onChange={(e) => handleFilterChange(e, 'make')}>
            <option value="">All</option>
            {getUniqueValues('make').map((make) => (
              <option key={make} value={make}>{make}</option>
            ))}
          </select>
        </label>

        <label>
          Model:
          <select value={filters.model} onChange={(e) => handleFilterChange(e, 'model')}>
            <option value="">All</option>
            {getUniqueValues('model').map((model) => (
              <option key={model} value={model}>{model}</option>
            ))}
          </select>
        </label>

        <label>
          Year:
          <select value={filters.year} onChange={(e) => handleFilterChange(e, 'year')}>
            <option value="">All</option>
            {getUniqueValues('year').map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </label>

        <label>
          Color:
          <select value={filters.color} onChange={(e) => handleFilterChange(e, 'color')}>
            <option value="">All</option>
            {getUniqueValues('color').map((color) => (
              <option key={color} value={color}>{color}</option>
            ))}
          </select>
        </label>
      </div>

      {filteredCars.length > 0 ? (
        <table className="car-table">
          <thead>
            <tr>
              <th>Car</th>
              <th onClick={() => handleSort('make')}>Make{getSortIndicator('make')}</th>
              <th onClick={() => handleSort('model')}>Model{getSortIndicator('model')}</th>
              <th onClick={() => handleSort('year')}>Year{getSortIndicator('year')}</th>
              <th onClick={() => handleSort('price')}>Price{getSortIndicator('price')}</th>
              <th onClick={() => handleSort('mileage')}>Mileage{getSortIndicator('mileage')}</th>
              <th onClick={() => handleSort('color')}>Color{getSortIndicator('color')}</th>
              <th onClick={() => handleSort('created_at')}>Date Added{getSortIndicator('created_at')}</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredCars.map((car, index) => (
              <tr key={index}>
                <td>
                  {car.url ? (
                    <div>
                      <a href={car.url} target="_blank" rel="noopener noreferrer">
                        <img
                          src={car.url}
                          alt="Car Thumbnail"
                          style={{ width: "100px", height: "auto", display: "block" }}
                          onError={(e) => { e.target.style.display = 'none'; }}
                        />
                        View Car
                      </a>
                    </div>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td>{car.make}</td>
                <td>{car.model}</td>
                <td>{car.year}</td>
                <td>${car.price.toLocaleString()}</td>
                <td>{car.mileage ? car.mileage.toLocaleString() : "N/A"}</td>
                <td>{car.color || "N/A"}</td>
                <td>{calculateDaysAgo(car.created_at)}</td>
                <td>
                  <button className="delete-button" onClick={() => handleDeleteCar(car.id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No cars available</p>
      )}
    </div>
  );
}

export default Dashboard;
